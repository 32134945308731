<template>
  <div class="account-settings" v-if="user">
    <div class="container">
      <base-heading bold="bold" class="dashboard-heading">
        {{ $t("accountSettings.heading") }}
      </base-heading>
      <div class="account-settings__layout">
        <div class="account-settings__left">
          <base-card class="account-settings__user">
            <div class="account-settings__user-inner">
              <router-link :to="`/instructor/${user.slug}`">
                <user-avatar
                  :user="user"
                  size="big"
                  class="account-settings__user-avatar"
                />
              </router-link>
              <div>
                <router-link
                  :to="`/instructor/${user.slug}`"
                  class="account-settings__user-name"
                >
                  {{ `${user.firstName} ${user.lastName}` }}
                </router-link>
                <button
                  type="button"
                  @click="activeSection = 'editProfile'"
                  class="account-settings__edit-button"
                  v-if="userType === 'creator'"
                >
                  {{ $t("editProfile.edit") }}
                  <img
                    src="/assets/icons/pencil.svg"
                    alt=""
                    class="account-settings__edit-button-icon"
                  />
                </button>
              </div>
            </div>
          </base-card>
          <base-card>
            <nav class="account-settings__navigation">
              <ul class="account-settings__list">
                <li
                  class="account-settings__list-item"
                  v-for="link in navigationLinks"
                  :key="link.component"
                >
                  <base-button
                    class="account-settings__link"
                    type="text"
                    :class="
                      activeSection === link.component
                        ? 'account-settings__link--active'
                        : ''
                    "
                    @click="activeSection = link.component"
                  >
                    {{ link.name }}
                  </base-button>
                </li>
              </ul>
            </nav>
          </base-card>
        </div>
        <div class="account-settings__right">
          <transition name="page-fade" mode="out-in">
            <component
              :is="activeSection"
              v-bind="$t('accountSettings')"
              v-if="user"
            />
          </transition>
          <base-button
            type="text"
            class="account-settings__button account-settings__button--suspend"
            @click="handleOpenModal"
            v-if="activeSection === 'account-details'"
          >
            <img :src="lockIcon" alt="" />
            <span class="account-settings__button-text">
              {{ suspendLabel }}
            </span>
          </base-button>
        </div>
      </div>
    </div>
    <base-modal
      v-model="isModalOpen"
      :title="$t('accountSettings.suspendAccount')"
      @accept="handleSuspendAccount"
    >
      <template slot="content">
        {{ $t("accountSettings.suspendInfo") }}
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getImageLink } from '@/helpers/index';

export default {
  name: 'AccountSettings',
  data: () => ({
    activeSection: '',
    isModalOpen: false,
  }),
  methods: {
    handleOpenModal() {
      if (!this.user.isSuspended) {
        this.isModalOpen = true;
        return;
      }
      this.changeSuspendSetting();
    },
    async changeSuspendSetting() {
      await this.$store.dispatch('auth/updateUserData', {
        ...this.user,
        isSuspended: !this.user.isSuspended,
      });
    },
    handleSuspendAccount() {
      this.isModalOpen = false;
      this.changeSuspendSetting();
    },
  },
  watch: {
    user() {
      if (this.preloaderStatus) {
        this.user ? this.$store.dispatch('ui/setPreloader', false) : false;
      }
    },
    activeSection() {
      const target = document.querySelector('body');

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
  },
  computed: {
    navigationLinks() {
      const components = ['account-details', 'change-password'];
      return components.map((component, index) => ({
        component,
        name: this.$t('accountSettings.navigationLinks')[index],
      }));
    },
    ...mapState({
      user: (state) => state.auth.user,
      preloaderStatus: (state) => state.ui.preloaderStatus,
    }),
    ...mapGetters({
      userType: 'auth/userType',
    }),
    lockIcon() {
      return this.user?.isSuspended
        ? '/assets/icons/lock-unlock.svg'
        : '/assets/icons/lock.svg';
    },
    suspendLabel() {
      return this.user?.isSuspended
        ? this.$t('accountSettings.resumeAccount')
        : this.$t('accountSettings.suspendAccount');
    },
    imageLink() {
      return getImageLink(this.user.avatarId);
    },
  },
  created() {
    !this.user ? this.$store.dispatch('ui/setPreloader', true) : false;
    this.activeSection = this.navigationLinks[0].component;
  },
};
</script>
<style lang="scss" scoped src="./AccountSettings.scss" />
